import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import Contactform from "../components/ContactForm";
import { Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { RE } from "../components/Reviews";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default () => (
  <Layout>
    <SEO
      title="Commercial Boiler Installation"
      description="BBIS Heating installs commercial boilers across London, Hertfordshire and surrounding areas. Contact us today"
      keywords="central heating service london,central heating services london,london central heating services,boiler and central heating service
      ,boiler central heating service,central heating service providers,oil central heating service,central heating boiler service,gas central heating service,central
       heating service,central heating services,central heating systems london,gas central heating system installation,oil central heating system,gas central heating
        system,new central heating system,electric central heating systems,central heating system,,"
    />
    <Jumbotron imgClassName="img18" />
<div class="white-segment">
  <h1 class="heading-color">Get a new gas, electric, LPG or oil boiler for your commercial premises</h1>
  <p>
    Is your commercial boiler old and inefficient? If your boiler is beginning to cost more and more to run or you’re constantly getting it repaired, it might be time for a new one.
  </p>
  <p>
    Whether you need just one commercial boiler, multiple boilers or even a whole <a href="/blog/what-is-a-plant-room/">plant room</a>, BBIS Heating can help you with a brand new commercial heating system for your business.
  </p>
  <h2 class="heading-color">Specialist boiler and plant room design</h2>
  <p>We specialise in designing and installing boiler and plant rooms to help you maximise the space that matters in your commercial property, whether it’s office space, flats or warehousing.</p>
  <p>We’ll carefully plan a new boiler room for you, ensuring that all plant and pipework fits into the space.</p>
  <h3 class="heading-color">How BBIS Commercial Heating can help</h3>
  <ul>
    <li>Remove ageing boilers and install a sealed system</li>
    <li>Design and fabricate new pipework</li>
    <li>Fit custom-built electrical control panels</li>
    <li>Install instantaneous hot water plate heat exchangers (PHEs)</li>
  </ul>
  <h3 class="heading-color">Recent commercial boiler installation projects</h3>
  <h4><strong>Portland Place, London</strong></h4>
  <p>We installed instantaneous hot water plate heat exchangers (PHEs) in Portland Place to provide hot water for 12 flats with multiple bedrooms and bathrooms. The PHE removes the need for a storage vessel, freeing up space in the boiler room. Plus, it also reduces running costs as a PHE is highly efficient.</p>
  <StyledButton
        href="/commercial-plant-room-installation-portland-place/"
        variant="primary"
        size="lg"
      >
        View Portland Street project
  </StyledButton>
  <p className="pt-3">For a professional commercial boiler installation in London, Hertfordshire and the surrounding areas, contact us today on 0800 2289800 or fill out our form below.</p>
</div>
<div className="grey-segment">
  <h3 class="heading-color">Why choose BBIS Commercial Heating?</h3>
  <ul>
    <li>50 years of experience in domestic and commercial heating</li>
    <li>Specialists in designing boiler and plant rooms</li>
    <li>5-star ratings and reviews</li>
    <li>Friendly local engineers in London, Hertfordshire and surrounding areas</li>
  </ul>
  <h5 class="heading-color">FAQs</h5>
  <h5 class="heading-color">How long does it take to install a commercial boiler?</h5>
  <p>How long it takes to install a commercial boiler depends on the complexity of the project. A simple swap of one boiler to another may take as little as a <strong>day,</strong> whereas a complete redesign of your boiler room with new plant and pipework could <strong>take two weeks or more.</strong></p>
  
  <h5 class="heading-color">How often should a commercial boiler be replaced?</h5>
  <p>A commercial boiler should be replaced when it comes to the end of its life, usually in around <strong>10 to 15 years.</strong> However, how long a commercial boiler lasts depends on how well it’s maintained. If you have your <a href="/commercial-boiler-servicing/">commercial boiler serviced</a> regularly, you will maximise its efficiency as well as its lifespan.</p>
  
  <h5 class="heading-color">How much does a new commercial boiler cost?</h5>
  <p>How much a new commercial boiler costs comes down to the <strong>type of boiler</strong> you need, whether it is one of a <strong>system of boilers</strong> in a boiler room and how <strong>straightforward</strong> the installation will be. Call us on 0800 2289800 or fill in our form below to get a quote.</p>
  </div>
  <div className="white-segment">
      <RE></RE>
      <Contactform></Contactform>
    </div>
  </Layout>
);
